
.myText {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Luckiest Guy', cursive;
}

.h-1 {
    font-size: 15vw;
    background-image: url(https://unsplash.it/800);
    background-size: cover;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}