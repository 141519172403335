.homeCarouselItem {
    height: 480px;
}

.homeCarouselImage {
    height: 100%;
    object-fit: cover;
    filter: brightness(0.6);
}

.success {
    width: 70%;
    color: green;
    font-size: larger;
    font: bold;
}

.errorMessage {
    color: red;
    text-align: left;
}

.homePlanAnalyicon {
    font-size: 50px;
    color: violet;
    border-radius: 50%;
    padding: 40px;
}

.homeDesignDevelopicon {
    font-size: 50px;
    color: brown;
    border-radius: 50%;
    padding: 40px;
}

.homeTestModifyicon {
    font-size: 50px;
    color: blue;
    border-radius: 50%;
    padding: 40px;
}

.homeMaintainSupporticon {
    font-size: 50px;
    color: darkgoldenrod;
    border-radius: 50%;
    padding: 40px;
}

.homeabout {
    width: 100%;
    height: 30%;
    background: lightgrey;
}

.homevideobg {
    width: 100%;
    height: 50%;
}

video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.home1MaintainSupporticon {
    font-size: 50px;
    color: #d50c1a;
    border-radius: 50%;
    padding: 40px;

}

.home2MaintainSupporticon {
    font-size: 50px;
    color: #22ED93;
    border-radius: 50%;
    padding: 40px;

}

.home3MaintainSupporticon {
    font-size: 50px;
    color: #F67E09;
    border-radius: 50%;
    padding: 40px;

}

label {
    font-size: 21px;
    font-weight: 500;

}


.rabasaClient {
    height: 40%;
    width: 50%;
}

@media (max-width: 767px) {
    .rabasaClient {
        height: 100%;
        width: 50%;
    }

    .gContainerFluid img {
        height: 350px;
    }
}