@import 'https://fonts.googleapis.com/css?family=Lily+Script+One';

#why-us-items {
  padding-left: 30%;
}


/*
commenting this as of now. need to see where an all it is going to effect
span {
  padding-left: 5%;
} */

.about-row-first {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 10em;
    padding-right: 10em;
}

.content-font-family{
    font-family: 'Roboto';
    font-size: 20px;
    text-align: justify;
}

.about-img-shdw {
    background-color: lightgreen;
    border-radius: 10px;
    box-shadow: 8px 8px 0px 0px rgba(152, 147, 147, 0.25);
    
  }
.about-img {
    border-radius: 10px;   
  }
