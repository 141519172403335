
.image img {
    max-width: 100%;
    max-height: 100%;
    -webkit-transition: .2s all;
    filter: brightness(0.4);

}

.image img:hover {
  filter: brightness(0.5);
}


.flip-card {
  margin-top: 5%;
  background-color: transparent;
  width: 400px;
  height: 400px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: rgb(254, 254, 254);
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}

#serviceFlashCards{
  padding-left: 10%;
}

/* Media queries */
@media screen and (max-width: 700px) {
  #serviceFlashCards{
    padding-left: 0%;
  }
  .flip-card{
    width: 100%;
  }
}

