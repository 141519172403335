.pass {
  color: green;
}

.fail {
  color: red;
}

.markspage {
  margin-top: 2rem;
  margin-bottom: 9rem;
}
