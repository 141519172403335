@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;1,100;1,300&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  min-height: 100vh; margin:0;
  display:flex; flex-direction:column;
}

section {
  flex:1;
}

.g-mrg-top {
  margin-top: 7rem!important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.required-star {
  color: red;
}

.app-check {
  height: 20px;
  width: 20px;
  margin: 1%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.gCentered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
}



.gContainerFluid {
  position: relative;
  text-align: center;
  color: whitesmoke;
}


.gVideo video {
  max-width: 100%;
  max-height: 100%;
  -webkit-transition: .2s all;
  filter: brightness(0.4);

}

.gVideo video:hover {
  filter: brightness(0.5);
}

.gCenteredVideo {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;

}

@media (max-width: 767px) {
  .gCentered {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4rem;
  }

  .gCenteredVideo {
    color: white;
    background-color: grey;
    position: relative;
    top: 0;
    left: 0;
    transform: translate(-0%, -0%);
    font-size: 1.2rem;
  }

}
